<template>
    <div class="content_">
          <div class="contact_title">
            <h2>商务合作 </h2>
          </div>
          <div>
            <div v-for="(item,index) in datas" :key="index" :id="'contact_item_'+item.id" class="contact_item">
              <span class="contact_item_desc">{{ item.contactName }}:</span>
              <span class="contact_item_val">{{ item.value }}</span>
            </div>
          </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'contact',
    data(){
      return {
        datas:[
          {id:'c001',contactName:'邮箱',value:'3965198705@qq.com'},
          {id:'c002',contactName:'QQ',value:'3965198705'},
          {id:'c003',contactName:'微信',value:'kfstrcos'}
        ]
      }
    },
    methods: {

    }
  }
  </script>
  
  <style>
  .contact_item {
    font-weight: bold;
    width: auto;
  }
  .contact_item_desc {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    text-align: right;
    padding-right: 10px;
  }
  .contact_item_val {
    display: inline-block;
  }
  </style>